import React , { useRef, useState, useEffect} from 'react';
import Loader from "../components/Loader";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Modal from '../components/Modal';
import { getJsonFile } from '../scripts/getFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

library.add(faDownload)

function ListItem(props) {
    const modal = useRef(null);
    const path = 'https://d3jgq4e568z7ip.cloudfront.net/original/';

    return (
        <li>
            <LazyLoad height={370}  offset={100}>
                <img className="animated" src={ path + props.value } onClick={() => modal.current.open()} alt="" /> 
                { props.action === 'search' ? 
                    <a className="downloadIcon" download={ props.value } href={ path + props.value } ><FontAwesomeIcon icon="download" /></a>
                    : null
                }
            </LazyLoad>
            
            <Modal ref={modal}>
                <LazyLoad height={370}>
                    <PinchZoomPan maxScale={3} zoomButtons={false} position="center">
                        <img className="animated" src={path + props.value} alt="" />
                    </PinchZoomPan>
                </LazyLoad>
            </Modal>
        </li>
    );
  }

const Result = ({ barCode, action }) => {
    
    const { t } = useTranslation();
    const [assets, setAssets] = useState([]);
    // const [loader, setLoader] = usestate(false);
    let regex = new RegExp(barCode,"g");

    useEffect(() => {
        async function featchData() {
            const data = await getJsonFile();
        setAssets(data.Assets.Asset);
        }
        featchData();
     },[setAssets]);

    var imagesResourcesFiltered = assets.filter((obj) => obj.SKU.match(regex));
    if (assets.length > 0) {
        if( imagesResourcesFiltered.length ){
            const listItems = imagesResourcesFiltered.map((resource, index) => 
                <ListItem key={index.toString()} value={resource.Filename} action={ action } />
            );
            
            return (
                <ul className="scannerResults">
                {listItems}
                </ul>
            );
        }

        if( action === 'search'){
            return (
                <div className="noScannerResults"> <p>{t('search.noResultsMessage')}</p></div>
            );
        }

        return (
            <div className="noScannerResults"> <p>{t('result.noResultsMessage')}</p></div>
        );

    } else {
        return (
            <Loader/>
        );
    }
}

Result.propTypes = {
    result: PropTypes.object
};

export default Result;
