import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import '../css-assets/search.css';

import ResultList from '../scripts/result';
import Filters from './Filters';

const Main = () => {
    const { t } = useTranslation();

    const [filtering, setFiltering] = useState(true);
    const [results, setResults] = useState(null);
    const [productName, setProductName] = useState('');
    const [productSKU, setProductSKU] = useState('');

    const handleResultSet = (res , productNameRes) => {
        setResults(res);
        console.log(productNameRes)
        if(productNameRes !== undefined)
            setProductName(productNameRes);
            setProductSKU(res);
        setFiltering(!filtering);
    }

    const refilterHandler = () => {
        setResults(null);
        setFiltering(true);
    }

    return (
            <div className="main">
                <div className={ filtering ? "show" : "hide" }>
                    <Filters handleResultSet={ handleResultSet } />
                </div>
                {results && !filtering ? 
                    <div className="results-wrap">
                        <div className="results-header">
                            <p>{ productName } ({ productSKU }) </p>
                            <button onClick={ refilterHandler }>{ t('search.backButton') }</button>
                        </div>
                        <ResultList barCode={ results } action={ 'search' }/> 
                    </div>
                : null}
            </div>
    )
};

export default Main;
