import React, { useState, useRef, useEffect } from 'react';
import ScannerB from '../scripts/scanner';
import { useTranslation } from 'react-i18next';

let Scanner = ({ handleResultSet }) => {
    const [scanning, setScanning] = useState(false);
    const scannerRef = useRef(null);
    const canvasRef = useRef(null)
    const { t } = useTranslation();

    const getResult = (result) => {
        handleResultSet(result);
    }

    const buildCanvas = () => {
        const ctx = canvasRef.current.getContext("2d");

        const recWidth = canvasRef.current.width+190;
        const recHeight = canvasRef.current.height-100;
        const xPos = (canvasRef.current.width/2) - (recWidth/2);
        const yPos = (canvasRef.current.height/2) - (recHeight/2);
        const lineWidth = 300;

        // Red line
        ctx.beginPath();
        ctx.moveTo((canvasRef.current.width/2) - lineWidth/2, canvasRef.current.height/2);
        ctx.lineTo((canvasRef.current.width/2) + lineWidth/2, canvasRef.current.height/2);
        ctx.lineWidth = "2";
        ctx.strokeStyle = 'rgba(255,0,0,1)';
        ctx.stroke();

        // Rectangle
        ctx.beginPath();
        ctx.rect(xPos,yPos,recWidth,recHeight);
        ctx.strokeStyle = 'rgba(0,0,0,.5)';
        ctx.lineWidth = "300";
        ctx.stroke();

    }

    useEffect(() => {
        setScanning(true);
        buildCanvas();
     }, []);



    return (
        <>
            <h2 className="scanner-title">{t('main.mainText')}</h2>
            <div className="scanner-wrapper">
                <div ref={scannerRef} className="camera">
                    <canvas className="scanner-overlay" ref={canvasRef} width={720} height={720}/>
                    <canvas className="drawingBuffer" />
                    {scanning ? <ScannerB scannerRef={scannerRef} onDetected={(result) => getResult(result)} /> : null}
                </div>
            </div>
        </>
    );
};

export default Scanner;
