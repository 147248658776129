import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const detectionOptions = {
    order: ['querystring', 'navigator']
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: detectionOptions,
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    intro: 'Scan for Ideas, Inspiration & More',
                    header: 'Header Component',
                    main: {
                        mainText: 'Scan any product code',
                    },
                    result: {
                        title: 'Ideas, Inspiration & More!',
                        noResultsMessage: 'There are no results for this product, try to scan another one'
                    },
                    notSafriOnIOS: 'Please open this link on Safari browser',
                    search: {
                        freeSearch: 'Search SKU or product name:',
                        placeholderFreeSearch: 'Enter SKU or product name',
                        category: 'Category',
                        placeholderCategory: 'placeholderCategory',
                        category1: 'Category 1',
                        placeholderCategory1: 'placeholderCategory1',
                        category2: 'Category 2',
                        placeholderCategory2: 'placeholderCategory2',
                        noResultsMessage: 'There are no results for this search',
                        backButton: 'back'
                    }

                }
            },
            fr: {
                translation: {
                    intro: 'Recherchez des idées, de l\'inspiration et plus encore',
                    header: 'Composant d\'en-tête',
                    main: {
                        mainText: 'Scannez un produit'
                    },
                    result: {
                        title: 'Images d\'inspiration de produit',
                        noResultsMessage: 'Il n\'y a aucun résultat pour ce produit, essayez d\'en scanner un autre'
                    },
                    notSafriOnIOS: 'Veuillez ouvrir ce lien sur le navigateur Safari',
                    search: {
                        freeSearch: 'Rechercher le SKU ou le nom du produit :',
                        placeholderFreeSearch: 'Saisissez le SKU ou le nom du produit',
                        category: 'Catégorie',
                        placeholderCategory: 'placeholderCategory',
                        category1: 'Catégorie 1',
                        placeholderCategory1: 'placeholderCategory1',
                        category2: 'Catégorie 2',
                        placeholderCategory2: 'placeholderCategory2',
                        noResultsMessage: 'Il n\'y a pas de résultats pour cette recherche',
                        backButton: 'retourner'
                    }
                }
            }
        }
    });

export default i18n;
