import './App.css';
import React, {useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Intro from './components/Intro';
import Header from './components/Header';
import Main from './components/Main';
import Search from './components/Search';




const App = () => {
    const { t, i18n } = useTranslation();

    const [currentLang, setCurrentLang] = useState('FR');
    const [showApp, setShowApp] = useState(false);

    const startScannerHandler = () => {
        console.log('works');
        setShowApp(!showApp);
    }

    useEffect(() => {
        if (window.location.href.indexOf("lang=fr") > -1) {
            setCurrentLang('EN');
            i18n.changeLanguage('fr');
        } else if(window.location.href.indexOf("lang=en") > -1) {
            setCurrentLang('FR');
            i18n.changeLanguage('en');
        }

        if(i18n.language.includes('fr')) {
            setCurrentLang('EN');
            i18n.changeLanguage('fr');
        }
    }, [i18n]);

    const changeLanguageHandler = (lang) => {
        if(lang === 'FR') {
            i18n.changeLanguage('fr');
            setCurrentLang('EN');
        } else {
            i18n.changeLanguage('en');
            setCurrentLang('FR');
        }
    }

    // const showInventory = () => {
    //     if(window.location.pathname === '/inventory') {
    //         return <Inventory />;
    //     }
    // }

    const iOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    // Firefox 1.0+
    const isFirefox = navigator.userAgent.match("FxiOS");

    // Chrome 1 - 71
    const isChromeIOS = (/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent));

    // if(window.location.pathname === '/inventory'){
    if(window.location.href.indexOf("page=inventory") > -1){    
        return (
            <div className="App">
                <Header currentLang={currentLang} changeLanguageHandler={changeLanguageHandler}/>
                <Search />
            </div>
        );
    } else {
        if (iOS && (isFirefox || isChromeIOS)) {
            return (
                <div className="App">
                    <Header currentLang={currentLang} changeLanguageHandler={changeLanguageHandler}/>
                    <div className="notSafari">
                        <p>{t('notSafriOnIOS')}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="App">
                   {showApp ? <><Header introSwitch={startScannerHandler} currentLang={currentLang}
                                     changeLanguageHandler={changeLanguageHandler}/>
                    <Main/></> : <Intro startScannerHandler={startScannerHandler}/>}
            </div>
          );
        }
    }
}

export default App;
