import { useTranslation } from 'react-i18next';


const Intro = ({startScannerHandler}) => {
    const { t } = useTranslation();

    return (
        <div className="intro-container">
            <h1 className="intro-container__header">{t('intro')}</h1>
            <div className="intro-container__icon" onClick={startScannerHandler}>
                <button>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 299.24 297.1" >
                    <rect className="st4" x="55.29" y="74.37" width="10.68" height="159.03"/>
                    <rect className="st4" x="99.19" y="74.37" width="10.68" height="159.03"/>
                    <rect className="st4" x="143.1" y="74.37" width="10.68" height="159.03"/>
                    <rect className="st4" x="187.01" y="74.37" width="10.68" height="159.03"/>
                    <rect className="st4" x="230.92" y="74.37" width="10.68" height="159.03"/>
                    <g>
                        <polyline className="st3" points="85.17,23.34 24.41,23.34 24.41,84.1"/>
                        <polyline className="st3" points="214.07,273.76 274.83,273.76 274.83,213"/>
                        <polyline className="st3" points="274.83,84.1 274.83,23.34 214.07,23.34"/>
                        <polyline className="st3" points="24.41,213 24.41,273.76 85.17,273.76"/>
                    </g>
                </svg>
                </button>
            </div>
            <div className="pseudo-element"></div>
        </div>
    )
}

export default Intro;
