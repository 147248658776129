import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react'
import Select, { createFilter } from 'react-select';
import data from '../data/data.json';

const Filters = ({ handleResultSet }) => {
    const { t } = useTranslation();
    const [mergedResults, setMergedResults] = useState([]);
    const [initFilters, setInitFilters] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };
    
    // A state that contains the variable content of the drop-down lists
    const [filtersCategory, setFiltersCategory] = useState({Category:[],Category1:[],Category2:[]});

    // A state that contains the selected content from the drop-down lists
    const [valueDD, setValueDD] = useState({Category:[],Category1:[],Category2:[]});

    // A place holder for the drop-down lists
    const customPlaceholderFreeSearch = t('search.placeholderFreeSearch');
    const customPlaceholder = t('search.placeholderCategory');
    const customPlaceholder1 = t('search.placeholderCategory1');
    const customPlaceholder2 = t('search.placeholderCategory2');
    
    const productsData = data.Sheet1;
    
    useEffect( () => {
        initLists('once', searchResults);

        let results = [];
        
        if(searchTerm !== ''){
            productsData.forEach( (obj, index) => {
                if(obj.Name.toLowerCase().includes(searchTerm.toLowerCase()))
                    results.push( { sku: obj.SKU, productName: obj.Name})
                if(obj.SKU.toLowerCase().includes(searchTerm.toLowerCase()))
                    results.push( { sku: obj.SKU, productName: obj.Name})
            })   
        }
        
        setSearchResults(results);
        updateDropDownList(valueDD, results)
        showResults(results, valueDD);
        
    }, [searchTerm]);
       
    const initLists = (whenToRun, freeSearchResults) => {
        if( (whenToRun === 'once' && !initFilters) || whenToRun === 'clear-filters' ){
            let tempList = {Category:[],Category1:[],Category2:[]};
            productsData.forEach( (obj) => {
                tempList = addItemToList(tempList, obj, 'Category', freeSearchResults);
                tempList = addItemToList(tempList, obj, 'Category1', freeSearchResults);
                tempList = addItemToList(tempList, obj, 'Category2', freeSearchResults);
            })

            tempList['Category'].sort( compare );
            tempList['Category1'].sort( compare );
            tempList['Category2'].sort( compare );
            setFiltersCategory(tempList);

            const tempValues = {Category:[],Category1:[],Category2:[]}
            setValueDD(tempValues);

            if( !initFilters ){
                setInitFilters(true);
            }

        }
    }


    const onChangeCategory = (inputValue, { action, option, removedValue, name }) => {
        const selectedDD = name;

        const temp = {...valueDD};
        temp[selectedDD] = inputValue;
        setValueDD(temp);

        updateDropDownList(temp, searchResults);
        showResults(searchResults, temp);
    }

    const updateDropDownList = (updatedValueDD, freeSearchResults) => {
        let tempList = {Category:[],Category1:[],Category2:[]};
        const caterory = 'Category';
        const caterory1 = 'Category1';
        const caterory2 = 'Category2';

        if( updatedValueDD[caterory].length !== 0 || updatedValueDD[caterory1].length !== 0 || updatedValueDD[caterory2].length !== 0 ){
            productsData.forEach( (obj) => {

                const isCategoryMatch = isItemInList(updatedValueDD[caterory], obj.Category);
                const isCategory1Match = isItemInList(updatedValueDD[caterory1], obj.Category1);
                const isCategory2Match = isItemInList(updatedValueDD[caterory2], obj.Category2);
    
                if( isCategoryMatch && isCategory1Match && isCategory2Match){
                    tempList = addItemToList(tempList, obj, caterory, freeSearchResults);
                    tempList = addItemToList(tempList, obj, caterory1, freeSearchResults);
                    tempList = addItemToList(tempList, obj, caterory2, freeSearchResults);
                }

                if(updatedValueDD[caterory].length !== 0 && updatedValueDD[caterory1].length === 0 && updatedValueDD[caterory2].length === 0){
                    tempList = addItemToList(tempList, obj, caterory, freeSearchResults);
                }

                if(updatedValueDD[caterory].length === 0 && updatedValueDD[caterory1].length !== 0 && updatedValueDD[caterory2].length === 0){
                    tempList = addItemToList(tempList, obj, caterory1, freeSearchResults);
                }

                if(updatedValueDD[caterory].length === 0 && updatedValueDD[caterory1].length === 0 && updatedValueDD[caterory2].length !== 0){
                    tempList = addItemToList(tempList, obj, caterory2, freeSearchResults);
                }

            });
            
            tempList[caterory].sort(compare);
            tempList[caterory1].sort(compare);
            tempList[caterory2].sort(compare);
            setFiltersCategory(tempList);
        } else {
            initLists('clear-filters' ,freeSearchResults);
        }
    }

    const compare = (a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0);

    const addItemToList = (list, item, caterory, freeSearchResults) => {
        if( !list[caterory].map(x=> x.value).includes(item[caterory]) ){
            
            if(freeSearchResults.length === 0 || (freeSearchResults.filter(obj => obj.sku === item.SKU).length > 0)){
                list[caterory].push( { value: item[caterory], label: item[caterory] } )
            }
        }
        
        return list;
    }

    const isItemInList = (list, item) => { return ((list.length !== 0 && list.map((x) => x.value).includes(item)) || list.length === 0 );}

    const showResults = (freeSearchList, filtersValuesDD) => {
        let results = [];

        if( filtersValuesDD['Category'].length !== 0 || filtersValuesDD['Category1'].length !== 0 || filtersValuesDD['Category2'].length !== 0 ){
            productsData.forEach( (obj) => {

                const isCategoryMatch = isItemInList(filtersValuesDD['Category'], obj.Category);
                const isCategory1Match = isItemInList(filtersValuesDD['Category1'], obj.Category1);
                const isCategory2Match = isItemInList(filtersValuesDD['Category2'], obj.Category2);
    
                if( isCategoryMatch && isCategory1Match && isCategory2Match)
                    results.push( { sku: obj.SKU, productName: obj.Name})

            });
        } 

        if(freeSearchList.length > 0 && results.length > 0){ 
           const intersection = freeSearchList.filter(item1 => results.some(item2 => item1.sku === item2.sku));
           setMergedResults(intersection);
        } else
            if(results.length === 0)
                setMergedResults(freeSearchList);
            else
                setMergedResults(results);
    }

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: false,
        matchFrom: 'any',
      };

      const clickItem = (sku, productName) => {
        handleResultSet(sku, productName);
      } 

    return (
        <div className="search-wrapper">
            <div className="free-search-wrap">
            <p>{t('search.freeSearch')}</p>
                <input
                    type="text"
                    placeholder={customPlaceholderFreeSearch}
                    value={searchTerm}
                    onChange={handleChange}
                    />
            </div>
            <div className="filters-wrap">
                <div className="filter">
                    <p>{t('search.category')}</p>
                    <Select 
                        options={filtersCategory['Category']} 
                        onChange={onChangeCategory} 
                        filterOption={createFilter(filterConfig)}
                        isMulti
                        placeholder={customPlaceholder}
                        value = {valueDD['Category']}
                        name = 'Category'
                        className = "filter-select"
                    />
                </div>
            
                <div className="filter">
                    <p>{t('search.category1')}</p>
                    <Select options={filtersCategory['Category1']} 
                        onChange={onChangeCategory} 
                        filterOption={createFilter(filterConfig)}
                        isMulti 
                        placeholder={customPlaceholder1}
                        value = {valueDD['Category1']}
                        name = 'Category1'
                        className = "filter-select"
                    /> 
                </div>

                <div className="filter">
                    <p>{t('search.category2')}</p>
                    <Select options={filtersCategory['Category2']} 
                        onChange={onChangeCategory} 
                        filterOption={createFilter(filterConfig)}
                        isMulti 
                        placeholder={customPlaceholder2}
                        value = {valueDD['Category2']}
                        name = 'Category2'
                        className = "filter-select"
                    /> 
                </div>
            </div>
            
            {mergedResults.length > 0 ? 
                <div className="search-results">
                    <ul>
                        {mergedResults.map( (obj) => 
                            <li id={obj.sku} key={obj.sku} > 
                                <a onClick={() => clickItem(obj.sku, obj.productName)}>{ obj.productName + " (" + obj.sku + ")" } </a>
                            </li>
                        )}
                    </ul>
                </div>
            : 
                <div className="no-results">
                    {t('search.noResultsMessage')}
                </div>
            }
            
        </div>
    )
    
}


export default Filters;