import React from 'react';


const Header = ({ currentLang, changeLanguageHandler, introSwitch }) => {


    const [isSticky, setSticky] = React.useState(false);
    const ref = React.useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            setSticky(window.scrollY >= 10);
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);


    return (
        <header className={`${isSticky ? 'sticky' : ''}`} ref={ref}>
            <div className="header-wrap">
                <div className="main-logo" onClick={introSwitch}>
                    <svg width="89px" height="18px" viewBox="0 0 138 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g id="Demandware-WF" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Home---Small_Desktop" transform="translate(-444.000000, -80.000000)" fill="#231F20">
                        <g id="Header">
                        <g id="bouclair-logo-/-black" transform="translate(444.000000, 80.000000)">
                        <g id="bouclair-blk">
                        <path d="M132.435801,17.6619217 L132.435801,27.2327402 L127.495302,27.2327402 L127.495302,7.47330961 L132.43089,7.47330961 L132.43089,8.80355872 C134.19395,7.47330961 137.081637,7.47330961 137.66605,7.47330961 L137.66605,11.8825623 C134.301993,11.8825623 132.435801,13.1729537 132.435801,17.6619217 Z" id="_Compound_Path_" fillRule="nonzero"></path>
                        <polygon id="_Compound_Path_2" fillRule="nonzero" points="124.018292 27.2327402 119.10726 27.2327402 119.10726 7.47330961 124.018292 7.47330961"></polygon>
                        <path d="M118.753665,2.83487544 C118.754921,2.07648077 119.055137,1.35010231 119.58742,0.817592083 C120.119703,0.285081854 120.839826,-0.00931470092 121.587331,4.29859056e-16 C122.325765,-4.25430695e-05 123.033534,0.299581479 123.55291,0.832100812 C124.072287,1.36462015 124.360112,2.08578203 124.352242,2.83487544 C124.331116,4.37505968 123.105513,5.6184259 121.587331,5.63985765 C120.04986,5.6376844 118.793564,4.39410516 118.753665,2.83487544 Z" id="_Path_" fillRule="nonzero"></path>
                        <path d="M12.6508185,27.2427046 L0,27.2427046 L0,0.612811388 L11.9780071,0.612811388 C17.1886121,0.612811388 19.8012811,3.37793594 19.8012811,7.82704626 C19.8546999,9.88538629 18.9026139,11.837666 17.2573665,13.0434164 C19.467331,14.2341637 20.8129537,16.3814947 20.8129537,19.4156584 C20.8129537,24.1736655 17.6698932,27.2427046 12.6557295,27.2427046 L12.6508185,27.2427046 Z M4.94049822,5.21637011 L4.94049822,11.0903915 L11.9780071,11.0903915 C12.7725175,11.1302311 13.5480388,10.8358069 14.1215723,10.2765939 C14.6951059,9.71738083 15.0158481,8.94291394 15.0081139,8.13594306 C15.0081139,6.37224199 13.8491103,5.22135231 11.9780071,5.22135231 L4.94049822,5.21637011 Z M11.8650534,15.7288256 L4.94049822,15.7288256 L4.94049822,22.6042705 L11.9780071,22.6042705 C13.8834875,22.6042705 15.7595018,21.8370107 15.7595018,19.2263345 C15.7595018,16.9992883 14.3745907,15.73879 11.8650534,15.73879 L11.8650534,15.7288256 Z" id="_Compound_Path_3" fillRule="nonzero"></path>
                        <path d="M42.632669,17.3829181 C42.632669,23.2918149 38.2520285,27.7060498 32.8990036,27.7060498 C27.5459786,27.7060498 23.2046263,23.2918149 23.2046263,17.3829181 C23.2046263,11.4740214 27.5459786,6.97508897 32.9039146,6.97508897 C38.2618505,6.97508897 42.6375801,11.4590747 42.6375801,17.3779359 M37.6528826,17.4377224 C37.6528826,14.024911 35.5165836,11.6434164 32.8990036,11.6434164 C30.2814235,11.6434164 28.1451246,14.0049822 28.1451246,17.4377224 C28.1451246,20.7758007 30.2765125,23.0775801 32.8990036,23.0775801 C35.5214947,23.0775801 37.6528826,20.7608541 37.6528826,17.4377224" id="_Compound_Path_4" fillRule="nonzero"></path>
                        <path d="M54.276726,27.7060498 C48.8745907,27.7060498 45.7069751,24.0590747 45.7069751,18.8775801 L45.7069751,7.47330961 L50.6474733,7.47330961 L50.6474733,19.0320285 C50.6474733,20.1430605 51.0207117,22.7935943 54.276726,22.7935943 C55.2753403,22.8356786 56.2461455,22.4540971 56.956135,21.7404346 C57.6661245,21.0267721 58.0511979,20.0454607 58.0189324,19.0320285 L58.0189324,7.47330961 L62.9594306,7.47330961 L62.9594306,18.8775801 C62.9594306,23.9046263 59.5217082,27.7060498 54.276726,27.7060498" id="_Path_2" fillRule="nonzero"></path>
                        <path d="M75.9883986,27.7060498 C70.1884698,27.7060498 66.0337367,23.0975089 66.0337367,17.3829181 C66.0337367,11.6683274 70.1835587,6.97508897 75.9883986,6.97508897 C80.0056228,6.97508897 83.1781495,8.96797153 84.6907473,12.4106762 L79.9958007,14.1145907 C79.2110639,12.6041593 77.6761267,11.6488325 75.9933096,11.6234875 C73.2627758,11.6234875 71.0528114,14.2341637 71.0528114,17.4177936 C71.0528114,20.5266904 73.2627758,23.0975089 75.9933096,23.0975089 C77.7089212,23.1016118 79.2794741,22.1217041 80.0498221,20.566548 L84.7447687,22.2854093 C83.2321708,25.7281139 80.0547331,27.7060498 76.0080427,27.7060498" id="_Path_3" fillRule="nonzero"></path>
                        <polygon id="_Compound_Path_5" fillRule="nonzero" points="93.1426335 27.202847 88.1923132 27.202847 88.1923132 0.627758007 93.1426335 0.627758007"></polygon>
                        <path d="M110.679929,7.47330961 L110.679929,8.75871886 C109.361279,7.3414444 107.507316,6.56517058 105.587189,6.62633452 C100.489537,6.62633452 96.2316726,10.86121 96.2316726,17.0142349 C96.2316726,23.1672598 100.474804,27.4021352 105.587189,27.4021352 C107.515795,27.474917 109.378934,26.6864476 110.68484,25.2448399 L110.68484,27.2377224 L115.595872,27.2377224 L115.595872,7.47330961 L110.679929,7.47330961 Z M106.338577,22.4199288 C103.88306,22.4199288 101.427544,20.6263345 101.427544,17.0441281 C101.427544,13.4619217 103.88306,11.6683274 106.338577,11.6683274 C108.951246,11.6683274 110.758505,13.66121 110.758505,17.0441281 C110.758505,20.4270463 108.946335,22.3950178 106.333665,22.3950178 L106.338577,22.4199288 Z" id="_Compound_Path_6" fillRule="nonzero"></path>
                        </g>
                        </g>
                        </g>
                        </g>
                        </g>
                    </svg>
                </div>
                <div className="lang-menu" onClick={() => changeLanguageHandler(currentLang)}>
                <span>{currentLang}</span>
                </div>
            </div>
        </header>
    )
};

export default Header;
