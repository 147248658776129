import { useTranslation } from 'react-i18next';
import ResultList from '../scripts/result';
import React from 'react';

const Result = ({ barCode, rescanHandler }) => {
    const { t } = useTranslation();

    return (
        <div className="results-wrapper">
            {!barCode
                ? <h2>{t('result.noResultsMessage')}</h2>
                : (
                    <>
                        <h2 className="results">{t('result.title')}</h2>
                        <ResultList barCode={barCode}/>
                    </>
                )
            }
            <div className="rescan-btn-wrapper">
                <button onClick={rescanHandler}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 299.24 297.1" >
                        <rect className="st3" x="55.29" y="74.37" width="10.68" height="159.03"/>
                        <rect className="st3" x="99.19" y="74.37" width="10.68" height="159.03"/>
                        <rect className="st3" x="143.1" y="74.37" width="10.68" height="159.03"/>
                        <rect className="st3" x="187.01" y="74.37" width="10.68" height="159.03"/>
                        <rect className="st3" x="230.92" y="74.37" width="10.68" height="159.03"/>
                        <g>
                            <polyline className="st3" points="85.17,23.34 24.41,23.34 24.41,84.1"/>
                            <polyline className="st3" points="214.07,273.76 274.83,273.76 274.83,213"/>
                            <polyline className="st3" points="274.83,84.1 274.83,23.34 214.07,23.34"/>
                            <polyline className="st3" points="24.41,213 24.41,273.76 85.17,273.76"/>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    )
};

export default Result;
