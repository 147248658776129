import React, { useState, useEffect } from 'react';
let Scanner = require('./Scanner').default;
let Result = require('./Result').default;

const Main = () => {
    const [scanning, setScanning] = useState(false);
    const [results, setResults] = useState(null);

    useEffect(() => {
        setScanning(!scanning);
    }, []);


    const handleResultSet = res => {
        setResults(res);
        setScanning(!scanning);
    }

    const rescanHandler = () => {
        setResults(null);
        setScanning(true);
    }

    return (
            <div className="main">
                {scanning ? <Scanner handleResultSet={handleResultSet} /> : null}
                {results && !scanning && <Result rescanHandler={rescanHandler} barCode={results}/>}
            </div>
    )
};

export default Main;
